import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Select,
  Option,
  Button,
  Tooltip,
  Slider,
  Container,
  Header,
  Main,
  Link,
  Divider,
  Backtop,
  Icon,
  Loading,
  MessageBox,
  Message,
  Notification,
  Drawer
} from "element-ui";

const components = [
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Select,
  Option,
  Button,
  Tooltip,
  Slider,
  Container,
  Header,
  Main,
  Link,
  Divider,
  Drawer,
  Backtop,
  Icon,
  Loading.directive
];

const element = {
  install: function(Vue) {
    Vue.prototype.$loading = Loading.service;
    Vue.prototype.$msgbox = MessageBox;
    Vue.prototype.$alert = MessageBox.alert;
    Vue.prototype.$confirm = MessageBox.confirm;
    Vue.prototype.$prompt = MessageBox.prompt;
    Vue.prototype.$notify = Notification;
    Vue.prototype.$message = Message;

    components.forEach(component => {
      Vue.use(component);
    });
  }
};

export default element;
