<template>
  <el-container>
    <div id="feedback">
      <el-tooltip class="item" effect="dark" content="Feedback" placement="top">
        <span class="contact-help text-warning" @click="goToFeedbackPage">
          <i class="fas fa-bug"></i>
        </span>
      </el-tooltip>
    </div>
    <el-header>
      <TOEFLHeader />
    </el-header>
    <el-main :class="isIpad ? 'ipad' : ''">
      <LandingPage />
    </el-main>
    <Footer />
    <el-backtop class="phone" :right="15" :bottom="40"></el-backtop>
    <el-backtop class="pc" :right="40" :bottom="100"></el-backtop>
  </el-container>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState } from "vuex";
/*eslint-enable */
import Footer from "@/components/Footer";
import TOEFLHeader from "@/components/TOEFLHeader";
import LandingPage from "@/views/LandingPage";

export default {
  metaInfo() {
    return {
      title: "TestAdmit | Practices for the TOEFL exam"
    };
  },

  components: {
    LandingPage,
    Footer,
    TOEFLHeader
  },

  mixins: [],

  props: [],
  data() {
    return {
      countryCode: "",
      existAdBlock: false
    };
  },
  computed: {
    ...mapState("user", ["lang", "profile"]),
    isIpad() {
      let isIpad = false;
      if (navigator.userAgent.indexOf("iPad") != -1) {
        isIpad = true;
      }
      return isIpad;
    }
  },
  methods: {
    async goToFeedbackPage() {
      window.open("mailto:support@testadmit.com", "_blank");
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/style";

.el-header {
  box-shadow: 0 5px 5px #aaa;
  background-color: white;
  position: fixed;
  width: 100%;
  z-index: 1001;
  padding: 0;
  margin: 0;
}

header.el-header {
  padding: 0;
  margin: 0;
}
.pc {
  display: flex !important;
}
.phone {
  display: none !important;
}


main.el-main {
  min-height: calc(100vh - 74px);
  padding: 0;
  padding-top: 100px;
}

.container {
  padding-top: 40px;
}
@media screen and (max-width: 992px) {
  main.el-main {
    padding-top: 60px;
  }
}

.toefl-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 25px;
}
.toefl-welcome {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>
<style scoped>
::v-deep #feedback .el-dialog__body {
  padding: 5px 40px;
}
::v-deep #feedback .el-alert {
  padding: 0px 16px;
}

.contact-help {
  right: 40px;
  bottom: 160px;
  position: fixed;
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: var(--themeColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-shadow: 0 0 6px rgb(0 0 0 / 12%);
  cursor: pointer;
  z-index: 100;
}
::v-deep .el-alert .el-alert__description {
  margin-top: 0;
}
::v-deep .el-upload-dragger {
  width: 450px;
  height: 200px;
}
@media screen and (max-width: 768px) {
  .phone {
    display: flex !important;
  }
  .pc {
    display: none !important;
  }

  ::v-deep .el-dialog--center .el-dialog__body {
    padding: 5px 20px;
  }
  ::v-deep .el-form--label-left .el-form-item__label {
    display: block;
    width: 100%;
    line-height: normal;
  }
  ::v-deep .el-upload-dragger {
    width: 100%;
    height: 150px;
  }
  ::v-deep .el-upload-dragger .el-icon-upload {
    margin: 20px 0 16px;
  }
  ::v-deep .el-upload {
    width: 100%;
  }
  ::v-deep .el-form-item {
    margin-bottom: 12px;
  }
  .contact-help {
    bottom: 100px;
    right: 15px;
  }
  ::v-deep #feedback .el-dialog__body {
    padding: 5px 15px;
  }
  ::v-deep.el-button + .el-button {
    margin-left: 0;
  }
}
</style>
